import React, {
  createContext, useMemo, useEffect, useCallback, useReducer,
} from 'react'
import { TimerContextProps, TimerProviderProps } from './timerContext.types'
import timerReducer from '../reducers/timerReducer'

export const TimerContext = createContext({} as TimerContextProps)
TimerContext.displayName = 'TimerContext'

const createInitialTimerState = () => ({
  brewTimer: {
    time: 0,
    reset: false,
    running: false,
    paused: false,
    referenceTime: 0,
  },
  brewBgTimer: {
    time: 0,
    running: false,
    referenceTime: 0,
  },
})

export const TimerProvider = ({ children }: TimerProviderProps) => {
  const [time, dispatch] = useReducer(
    timerReducer,
    null,
    createInitialTimerState,
  )

  const reset = () => {
    dispatch({ type: 'reset' })
  }

  const start = () => {
    dispatch({ type: 'start' })
  }

  const stop = () => {
    dispatch({ type: 'stop' })
  }

  useEffect(() => {
    if (time.brewTimer.running) {
      setTimeout(() => dispatch({ type: 'calculateTimer' }), 50)
    }
  }, [time, time.brewTimer.running])

  useEffect(() => {
    if (time.brewTimer.paused) {
      setTimeout(() => dispatch({ type: 'calculatePause' }), 50)
    }
  }, [time, time.brewTimer.paused])

  useEffect(() => {
    if (time.brewTimer.time >= 600000 || time.brewBgTimer.time >= 600000) {
      dispatch({ type: 'preventInfinite' })
    }
  }, [time.brewBgTimer.time, time.brewTimer.time])

  // Minutes calculation
  const minutes = useCallback((toConvert: number) => Math.floor((toConvert / 60000)), [])

  // Seconds calculation
  const seconds = useCallback((toConvert: number) => Math.floor((toConvert % 60000) / 1000), [])

  // Milliseconds calculation
  const milliseconds = useCallback((toConvert: number) => toConvert % 100, [])

  const value = useMemo(
    () => ({
      time,
      milliseconds,
      minutes,
      reset,
      seconds,
      start,
      stop,
    }),
    [milliseconds, minutes, seconds, time],
  )

  return (
    <TimerContext.Provider value={value}>
      {children}
    </TimerContext.Provider>
  )
}
