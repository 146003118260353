import React, {
  createContext, useMemo, useContext, useReducer, useEffect,
} from 'react'
import {
  BrewContextProps, BrewProviderProps, BrewTotals, createInitialBrewState,
} from './brewContext.types'
import { CalcContext } from './calcContext'
import brewReducer from '../reducers/brewReducer'

export const BrewContext = createContext({} as BrewContextProps)
BrewContext.displayName = 'BrewContext'

export const BrewProvider = ({ children }: BrewProviderProps) => {
  const { calcData } = useContext(CalcContext)
  const [brew, dispatch] = useReducer(
    brewReducer,
    {
      bloomMultiplier: 3,
      bloomSeconds: 45,
      bloomSteps: 1,
      bloomWater: Math.round(calcData.coffee * 3),
      coffee: calcData.coffee,
      seconds: 240,
      steps: 2,
      water: calcData.water,
    },
    createInitialBrewState,
  )

  const addTimeToStep = (stepIndex: number, timeAmount: number, bloom?: boolean) => {
    dispatch({
      type: 'add_time_to_step', bloom, stepIndex, timeAmount,
    })
  }

  const setTotals = (key: keyof BrewTotals, value: number) => {
    dispatch({ type: 'set_totals', key, value })
  }

  useEffect(() => {
    dispatch({ type: 'calculate_steps', calc: calcData })
  }, [calcData])

  // const updateStepStatuses = () => {
  //   if (time.brewTimer.running) {
  //     const currentTimer = time.brewTimer.time / 1000
  //     const applyStatus = (bloom: boolean, step: BrewItem, index: number) => {
  //       // Currently Pouring Step
  //       if (step.compoundSeconds < currentTimer && currentTimer < (step.compoundSeconds + step.seconds)) {
  //         return dispatch({
  //           type: 'change_step_status', status: true, stepIndex: index, bloom, statusName: 'pouring',
  //         })
  //       }
  //       // Currently Not Pouring
  //       if (step.compoundSeconds > currentTimer || currentTimer > step.compoundSeconds + step.seconds) {
  //         return dispatch({
  //           type: 'change_step_status', status: false, stepIndex: index, bloom, statusName: 'pouring',
  //         })
  //       }
  //       // Complete
  //       if (step.compoundSeconds + step.seconds < currentTimer) {
  //         return dispatch({
  //           type: 'change_step_status', status: true, stepIndex: index, bloom, statusName: 'complete',
  //         })
  //       }
  //       return Error
  //     }
  //     brew.bloom.map((step, index) => applyStatus(true, step, index))
  //     brew.steps.map((step, index) => applyStatus(false, step, index))
  //   }
  // }

  // useEffect(() => {
  //   if (time.brewTimer.reset) {
  //     dispatch({ type: 'calculate_steps', calc: calcData })
  //   }
  // }, [calcData, time.brewTimer.reset])
  //
  // const { throttledFn } = useThrottledFunction({
  //   callbackFn: updateStepStatuses,
  //   throttleMs: 1000,
  // })
  //
  // useEffect(() => {
  //   throttledFn()
  // }, [throttledFn])

  const value = useMemo(() => ({
    brew,
    addTimeToStep,
    setTotals,
  }), [brew])

  return (
    <BrewContext.Provider value={value}>
      {children}
    </BrewContext.Provider>
  )
}
