import { BrewItem } from '../context/brewContext.types'
import { CalcEntry } from '../context/calcContext.types'

type BrewAction =
    | {
          type: 'add_time_to_step'
          bloom?: boolean
          stepIndex: number
          timeAmount: number
      }
    | { type: 'calculate_steps'; calc: CalcEntry }
    | {
          type: 'edit_steps'
          bloom?: boolean
          stepIndex: number
          key: BrewItem
          value: number
      }
    | { type: 'change_step_status'; bloom?: boolean; stepIndex: number; statusName: | 'complete' | 'pouring'; status: boolean }
    | { type: 'refresh_totals'; values: CalcEntry }
    | { type: 'reset_to_equal_steps' }
    | { type: 'set_totals'; key: keyof BrewTotals; value: number }
    | { type: 'split_step'; stepIndex: number }

type BrewTotals = {
    bloomMultiplier: number
    bloomSeconds: number
    bloomSteps: 0 | 1 | 2 | 3
    bloomWater: number
    coffee: number
    seconds: number
    steps: 0 | 1 | 2 | 3 | 4 | 5 | 6
    water: number
}

type Brew = {
    bloom: BrewItem[]
    steps: BrewItem[]
    totals: BrewTotals
}

const hasEqualSeconds = (arr: BrewItem[]) => arr.every((step) => step.seconds === arr[0].seconds)
const hasEqualWater = (arr: BrewItem[]) => arr.every((step) => step.water === arr[0].water)

const brewReducer = (brew: Brew, action: BrewAction): Brew => {
  switch (action.type) {
    case 'add_time_to_step': {
      const stepsToChange = action.bloom ? brew.bloom : brew.steps
      const newSteps = stepsToChange.map((step, index) => {
        if (index === action.stepIndex) {
          step.seconds += action.timeAmount
        }
        return step
      })
      if (action.bloom) {
        const newTotals = {
          ...brew.totals,
          bloomSeconds: (brew.totals.bloomSeconds += action.timeAmount),
        }
        return { ...brew, bloom: newSteps, totals: newTotals }
      }
      if (!action.bloom) {
        const newTotals = {
          ...brew.totals,
          seconds: (brew.totals.seconds += action.timeAmount),
        }
        return { ...brew, steps: newSteps, totals: newTotals }
      }
      return brew
    }

    case 'calculate_steps': {
      const bloomWater = Math.round(action.calc.coffee * brew.totals.bloomMultiplier)
      const stepSeconds = brew.totals.seconds - brew.totals.bloomSeconds
      const stepWater = action.calc.water - bloomWater
      const waterPerStep = stepWater / brew.totals.steps
      const secondsPerStep = stepSeconds / brew.totals.steps
      if (hasEqualWater(brew.bloom) && hasEqualSeconds(brew.bloom) && hasEqualWater(brew.steps) && hasEqualSeconds(brew.steps)) {
        return {
          bloom: Array.from({ length: brew.totals.bloomSteps }, (_, index) => ({
            compoundSeconds: 0,
            compoundWater: bloomWater,
            gPerSecond: 5,
            pour: 'round',
            seconds: brew.totals.bloomSeconds,
            temperature: 90,
            timeCompleted: false,
            timePouring: false,
            water: bloomWater,
          })),
          steps: Array.from({ length: brew.totals.steps }, (_, index) => ({
            compoundSeconds: Math.round(secondsPerStep * index + brew.totals.bloomSeconds),
            compoundWater: Math.round(waterPerStep * (index + 1) + bloomWater),
            gPerSecond: 5,
            pour: 'round',
            seconds: Math.round(secondsPerStep),
            temperature: 90,
            timeCompleted: false,
            timePouring: false,
            water: Math.round(waterPerStep),
          })),
          totals: {
            ...brew.totals,
            bloomWater,
            coffee: action.calc.coffee,
            water: action.calc.water,
          },
        }
      }
      // if (!hasEqualSeconds(brew.bloom) || !hasEqualSeconds(brew.steps)) {
      //   let totalBloomSeconds = 0
      //   let totalBrewSeconds = 0
      //   brew.bloom.forEach((step, index) => { totalBloomSeconds += step.seconds })
      //   brew.steps.forEach((step, index) => { totalBrewSeconds += step.seconds })
      //   return {
      //
      //   }
      // }
      return brew
    }

    case 'change_step_status': {
      const stepsToChange = action.bloom ? brew.bloom : brew.steps
      const newSteps = stepsToChange.map((step, index) => {
        if (index === action.stepIndex) {
          if (action.statusName === 'pouring') step.timePouring = action.status
          if (action.statusName === 'complete') step.timeCompleted = action.status
        }
        return step
      })
      if (action.bloom) return { ...brew, bloom: newSteps }
      if (!action.bloom) return { ...brew, steps: newSteps }
      return brew
    }

    case 'refresh_totals': {
      return {
        ...brew,
        totals: {
          ...brew.totals,
          coffee: action.values.coffee,
          water: action.values.water,
        },
      }
    }
    //
    // case 'reset_to_equal_steps': {
    //   const newBloomWater = (Math.round(brew.totals.coffee * brew.totals.bloomMultiplier) * 100) / 100
    //   const newBloomSteps = Array.from({ length: brew.totals.bloomSteps }, (_, index) => ({
    //     ...brew.bloom[index],
    //     compoundSeconds: Math.round((brew.totals.bloomSeconds / brew.totals.bloomSteps) * index),
    //     water: Math.round(newBloomWater / brew.totals.bloomSteps),
    //     compoundWater: Math.round((newBloomWater / brew.totals.bloomSteps) * (index + 1)),
    //   }))
    //   const bloom = {
    //     ...brew.bloom,
    //     bloomInfo: newBloomSteps,
    //   }
    //   const brewSteps = Array.from({ length: brew.totals.steps }, (_, index) => ({
    //     ...brew.steps[index],
    //     compoundSeconds: Math.round(((brew.totals.seconds - brew.totals.bloomSeconds) / brew.totals.steps) * (index) + brew.totals.bloomSeconds),
    //     compoundWater: Math.round(((brew.totals.water - brew.totals.bloomWater) / brew.totals.steps) * (index + 1) + brew.totals.bloomWater),
    //     seconds: Math.round((brew.totals.seconds - brew.totals.bloomSeconds) / brew.totals.steps),
    //     water: Math.round((brew.totals.water - brew.totals.bloomWater) / brew.totals.steps),
    //   }))
    //   return { ...brew, bloom, steps: brewSteps }
    // }

    case 'set_totals': {
      return {
        ...brew,
        totals: { ...brew.totals, [action.key]: action.value },
      }
    }
    default: {
      return brew
    }
  }
}

export default brewReducer
