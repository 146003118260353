import React, {
  useState, createContext, useMemo, useEffect, useReducer,
} from 'react'
import { Settings, SettingsContextProps, SettingsProviderProps } from './settingsContext.types'
import settingsReducer from '../reducers/settingsReducer'

export const SettingsContext = createContext({} as SettingsContextProps)
SettingsContext.displayName = 'SettingsContext'

const createInitialSettingsState = () => {
  if (typeof window !== 'undefined') {
    if (localStorage.getItem('settings') !== null) {
      return JSON.parse(localStorage.getItem('settings') as string)
    }
  } return {
    presets: {
      calculator: {
        buttons: { coffee: [10, 16, 20, 40], ratio: [10, 12, 16, 17], water: [150, 200, 250, 600] },
        defaults: { display: [15, 16.5, 250] },
      },
      log: {
        dropdowns: {
          waterRecipe: ['Aquacode', 'TWW Light', 'Coffee Chronicler', 'Coffee Ad Astra'],
        },
      },
    },
    singleVars: {
      sortLog: 'descending',
      volume: 1,
    },
    switches: {
      debugMode: false,
      instructionMode: true,
      saveLogInBrowser: false,
      saveSettingsInBrowser: false,
    },
  }
}

export const SettingsProvider = ({ children }: SettingsProviderProps) => {
  const [settings, dispatch] = useReducer(settingsReducer, null, createInitialSettingsState)
  const [settingsModalOpen, setSettingsModalOpen] = useState(false)
  const [vertical, setVertical] = useState(true)

  const changePresets = (module: keyof Settings['presets'], presetName: string, value: | number[] | string[]) => {
    dispatch({
      type: 'setPreset', module, presetName, value,
    })
  }

  const changeSettingsSwitch = (name: keyof Settings['switches'], value: boolean) => {
    dispatch({ type: 'switchSetting', name, value })
  }

  const changeSingleSetting = (name: keyof Settings['singleVars'], value: string | number) => {
    dispatch({ type: 'changeVar', name, value })
  }

  useEffect(() => {
    if (settings.switches && settings.switches.saveSettingsInBrowser) localStorage.setItem('settings', JSON.stringify(settings))
    if (settings.switches && !settings.switches.saveSettingsInBrowser) localStorage.removeItem('settings')
  }, [settings])

  const value = useMemo(() => ({
    changePresets,
    changeSettingsSwitch,
    changeSingleSetting,
    settings,
    settingsModalOpen,
    setSettingsModalOpen,
    setVertical,
    vertical,
  }), [settings, settingsModalOpen, vertical])

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  )
}
