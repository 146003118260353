import { Settings } from '../context/settingsContext.types'

type SettingsAction =
    | { type: 'changeVar', name: keyof Settings['singleVars'], value: string | number }
    | { type: 'setPreset', module: keyof Settings['presets'], presetName: string, value: | number[] | string[] }
    | { type: 'switchSetting', name: keyof Settings['switches'], value: boolean }

const settingsReducer = (settings: Settings, action: SettingsAction): Settings => {
  switch (action.type) {
    case 'changeVar': {
      return {
        ...settings,
        singleVars: {
          ...settings.singleVars,
          [action.name]: action.value,
        },
      }
    }
    case 'setPreset': {
      return {
        ...settings,
        presets: {
          ...settings.presets,
          [action.module]: {
            ...settings.presets[action.module],
            [action.presetName]: action.value,
          },
        },
      }
    }
    case 'switchSetting': {
      return {
        ...settings,
        switches: {
          ...settings.switches,
          [action.name]: action.value,
        },
      }
    }
    default: {
      return settings
    }
  }
}

export default settingsReducer
